<template>
  <div style="height: 100%">
    <SourceTraceQrcode />
  </div>
</template>

<script>
import { SourceTraceQrcode } from '@/components/console/Index.vue';

export default {
  components: {
    SourceTraceQrcode,
  },
};
</script>
